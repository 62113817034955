<template>
    <v-row>
        <v-col :cols="12">
            <component-container
                :card-title="$t('view_externalSourcesManagement_title')"
                :card-subtitle="$t('view_externalSourcesManagement_subtitle')"

                :height="720"

                :information-tooltip="{
                    title: $t('view_externalSourcesManagement_informationTooltip_title'),
                    text: $t('view_externalSourcesManagement_informationTooltip_text')
                }"
                :component-data="{response:{isLoaded:true}, hasData:true}"
            >
                <template v-slot:content>
                    <div v-if="initComplete">
                            <data-source-configuration
                                class="mt-8"
                                :can-delete-account="true"
                                data-source-type="google_analytics"
                            />
                            <data-source-configuration
                                class="mt-8"
                                :can-delete-account="true"
                                data-source-type="matomo_analytics"
                            />
                            <data-source-configuration
                                class="mt-8"
                                :can-delete-account="true"
                                data-source-type="google_sheet"
                            />
                    </div>
                    <div class="v-application text-center" style="top: 50%; position: absolute; left: 50%" v-else>
                        <v-progress-circular indeterminate color="primary"/>
                    </div>
                </template>
            </component-container>



        </v-col>
    </v-row>

</template>


<script>
import DataSourceConfiguration from "@/components/yooda-components/DataSourceConfiguration.vue";

import analyticsApiCalls from "@/api/axios/configuration/analytics";
export default {
    components: {
        DataSourceConfiguration,
    },
    data(){
        return{
            initComplete:false
        }
    },
    computed:{

    },
    methods:{

    },
    mounted() {
        this.analyticsIdentify()

        this.setDataComponent({
            stateName:'analyticsDataSource',
            promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
            dataFormatter: this.formatDataSource,
            asyncFormatter:true

        }).then((response)=>{
            if(response){
                this.initComplete = true
            }
        })

    }

}

</script>
